import React from "react";
import PropTypes from "prop-types";
import Layout from "../components/Layout/Layout";
import NotFound from "../containers/NotFound/NotFound";

const NotFoundPage = ({ pageContext }) => {
	return (
		<Layout pageContext={pageContext}>
			<NotFound />
		</Layout>
	);
};

NotFoundPage.propTypes = {
	pageContext: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string]))
		.isRequired,
};
export default NotFoundPage;
