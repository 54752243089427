import { Link } from "gatsby";
import React, { useContext, useEffect } from "react";
import Heading from "../../components/Heading/Heading";
import { PAGE } from "../../helpers/constants";
import { scrollToTop } from "../../helpers/helpers";
import { LocaleContext } from "../../components/Layout/Layout";
import useTranslations from "../../hoc/useTranslations";
import { routes } from "../../routes";
import SEO from "../../components/seo";

import "./NotFound.scss";

const NotFound = () => {
	const { locale } = useContext(LocaleContext);
	const {
		errorPage: { not_found, go_home },
	} = useTranslations();

	useEffect(() => {
		scrollToTop();
	}, []);

	return (
		<>
			<SEO page={PAGE.INDEX} />
			<section className="section section__notFound">
				<Heading className="section__notFound--error" type={1}>
					404
				</Heading>
				<Heading className="section__notFound--heading" type={2}>
					{not_found}
				</Heading>
				<Link className="button section__notFound--link" to={routes[locale].home}>
					{go_home}
				</Link>
			</section>
		</>
	);
};

export default NotFound;
